import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';

const AccordianOverideStyles = styled.div`
  .root {
    width: "100%";
  }
  .heading {
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    color: #ed2924;
    line-height: 1.3;
    font-family: ${theme.font.nimbleFont};
  }

  .accordion-wrapper {
    background-color: #ed2924;
    padding: 10px 20px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #1e1e1e;
      text-align: left !important;
    }
    h3 {
      font-weight: bold;
      font-size: 22px;
      text-align: left;
      color: #ed2924;

      a {
        color: ${theme.colours.red} !important;
      }
    }
    .MuiExpansionPanelSummary-content {
      padding: 20px 40px !important;
      margin: 0 !important;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        padding: 20px 0 !important;
      }
    }
    .MuiIconButton-root {
      padding: 0 40px !important;
      margin: 0 !important;

      @media (max-width: ${theme.components.phoneWidthMax}) {
        padding: 0 !important;
      }
    }
    .MuiExpansionPanelDetails-root {
      box-sizing: border-box !important;
      .answer-wrapper {
        padding: 0 40px;
      }
    }
  }
  .MuiExpansionPanel-root {
    max-width: 1440px !important;
    margin: 0 auto !important;
  }
`;
export default AccordianOverideStyles;
