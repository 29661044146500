import React from 'react';
// MaterialUi Imports
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Styles
import AccordianOverideStyles from './style';

const ComponentAccordionPanel = ({ data }) => (
  <AccordianOverideStyles className="component-accordionpanel" id="anchor">
    <div className="accordion-wrapper">
      <div className="root">
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="heading">{data.accordionHeading}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              className="answer-wrapper"
              dangerouslySetInnerHTML={{ __html: data.html.html }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  </AccordianOverideStyles>
);
export default ComponentAccordionPanel;
